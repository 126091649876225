<template>
    <div class="content">
        <div class="row">
            
            <div class="col-md-12">
                <vuestic-widget :headerText="$t('view.team.title')">
                    <p>{{$t('view.team.description')}}</p>
                    <div class="row">
                        <div class="col-md-12 pull-right">
                            <button type="button" v-on:click="newTeam" class="btn btn-primary btn-sm">{{$t('view.team.new')}}</button>
                        </div>
                    </div>
                </vuestic-widget>
            </div>
            <div class="col-md-12">
                <table-team></table-team>
            
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import TableTeam from '../tables/TableTeam/TableTeam.vue'

    import {SpringSpinner} from 'epic-spinners'

    export default {
        name:       'Table',
        components: {
            SpringSpinner,
            TableTeam
        },
        data() {
            return {}
        },
        methods: {
            newTeam () {
                this.$router.push('team/new')
            }

        }
    }
</script>

<style lang="scss">
    
    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }
</style>
